import * as React from "react"
import { useEffect, useState, useRef } from 'react';
import Layout from "../components/layout"
import Seo from "../components/seo"
import icon1 from "../images/about_us_icon1.svg"
import icon2 from "../images/about_us_icon2.svg"
import circle1 from "../images/circle1.svg"
import circle2 from "../images/circle2.svg"
import circle3 from "../images/circle3.svg"
import circle1y from "../images/circle1y.svg"
import circle2y from "../images/circle2y.svg"
import circle3y from "../images/circle3y.svg"

import useIntersection from '../hooks/useIntersection'

import openGpaphImage from "../images/opengraph/main.jpg"


function About () {
    const [whiteCircleWidth, setWhiteCircleWidth] = useState(240);
    const [yelCircleWidth, setYelCircleWidth] = useState(32);
    const isBrowser = typeof window !== "undefined"

    const windowHeight = useRef( isBrowser ? window.innerHeight : null );

    const ref1 = useRef(null);
    const isCircleOneInViewport = useIntersection(ref1, '-250px');
    const ref2 = useRef(null);
    const isCircleTwoInViewport = useIntersection(ref2, '-250px');

    let oldScrollY = 0;
    const [direction, setDirection] = useState('up');
    const controlDirection = () => {
        if(window.scrollY > oldScrollY) {
            setDirection('down');
        } else {
            setDirection('up');
        }
        oldScrollY = window.scrollY;
    }
    
    useEffect(() => {
        window.addEventListener('scroll', controlDirection);
        return () => {
            window.removeEventListener('scroll', controlDirection);
        };
    },[]);

    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        if ((isCircleOneInViewport || isCircleTwoInViewport) && direction === 'down') {
            if (32 < whiteCircleWidth) {
                setWhiteCircleWidth(whiteCircleWidth - 10)  
            }
            if (yelCircleWidth < 240) {
                setYelCircleWidth(yelCircleWidth + 10)
            }
        } else if ((isCircleOneInViewport || isCircleTwoInViewport) && direction === 'up') {
            if (whiteCircleWidth < 240) {
                if(offset !== 0){
                    setWhiteCircleWidth(whiteCircleWidth + 10)
                } else {
                    setWhiteCircleWidth(240)
                } 
            }
            if (32 < yelCircleWidth) {
                if(offset !== 0){
                    setYelCircleWidth(yelCircleWidth - 10)
                } else {
                    setYelCircleWidth(32)
                } 
            }
        }          
    }, [offset]);


    

    return (
        <Layout>

        <section className="about-us">
        <div className="container">
            <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-12 col-md-12 col-12 text-center">
                    <h1>We provide fast-start, seamless <br />& affordable multi-tenant authorization</h1>
                </div>
                <div className="col-xl-8 offset-xl-2 col-lg-12 col-md-12 col-12">
                    <div className="container-intro shadow">
                        <p>The iamcore team is familiar with authorization issues not by hearsay. The backbone of iamcore service was created while our team was working on a user-heavy PaaS IoT platform.</p>
                        <p>
                        We have learned that implementing multi-tenant authorization is a time- and resource-consuming process that can take up to 32% of the development time.
                        </p>
                        <p>
                        That's why we decided to release our access authorization system as a standalone product to offer a fast-start multi-tenant IAM solution to growing SaaS startups.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </section>

        <section className="circles-section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-3 col-md-12 col-12 text-xl-start text-center">
                        <div className="h2-wrapper-2">
                            <h2>Multi-tenant authorization made simple</h2>
                        </div>
                    </div>
                    <div className="col-xl-9 col-md-12 col-12 text-end">
                        
                        { windowHeight.current < 2000 ?
                        <div className="row">
                            <div className="col-md-4 col-sm-12 col-8">
                                <div className="row">
                                    <div className="col-sm-10 offset-sm-1 col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex">
                                                    <div className="me-auto">
                                                        <img src={circle1} ref={ref1} style={{width: whiteCircleWidth}} alt='long_icon'/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="d-flex">
                                                    <div className="ms-auto">
                                                        <img src={circle1y} ref={ref2} style={{width: yelCircleWidth}} alt='fast_icon'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12 col-8">
                                <div className="row">
                                    <div className="col-sm-10 offset-sm-1 col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex">
                                                    <div className="me-auto">
                                                        <img src={circle2} ref={ref1} style={{width: whiteCircleWidth}} alt='boring_icon'/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="d-flex">
                                                    <div className="ms-auto">
                                                        <img src={circle2y} ref={ref2} style={{width: yelCircleWidth}} alt='seamless_icon'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12 col-8">
                                <div className="row">
                                    <div className="col-sm-10 offset-sm-1 col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex">
                                                    <div className="me-auto">
                                                        <img src={circle3} ref={ref1} style={{width: whiteCircleWidth}} alt='costly_icon'/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <div className="d-flex">
                                                    <div className="ms-auto">
                                                        <img src={circle3y} ref={ref2} style={{width: yelCircleWidth}} alt='affordable_icon'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-4 col-sm-12 col-8">
                                <div className="row">
                                    <div className="col-sm-10 offset-sm-1 col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex">
                                                    <div className="ms-auto">
                                                        <img src={circle1y} style={{width: 240}} alt='fast_icon'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12 col-8">
                                <div className="row">
                                    <div className="col-sm-10 offset-sm-1 col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex">
                                                    <div className="ms-auto">
                                                        <img src={circle2y} style={{width: 240}} alt='seamless_icon'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-sm-12 col-8">
                                <div className="row">
                                    <div className="col-sm-10 offset-sm-1 col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex">
                                                    <div className="ms-auto">
                                                        <img src={circle3y} œstyle={{width: 240}} alt='affordable_icon'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }                        
                    </div>
                </div>
            </div>
        </section>        




        <section className="common-section">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12 d-flex align-items-stretch">
                        <div className="white-bckg-block shadow">
                            <div className="d-flex flex-row">
                                <div><img src={icon1} className="img-fluid" alt='mission_icon'/></div>
                                <div><h3>Mission</h3></div>
                            </div>
                            <hr />
                            <p>
                            We help startups create and innovate as the pace picks up – with seamless and affordable access management.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 d-flex align-items-stretch">
                        <div className="white-bckg-block shadow">
                            <div className="d-flex flex-row">
                                <div><img src={icon2} className="img-fluid" alt='vision_icon'/></div>
                                <div><h3>Vision</h3></div>
                            </div>
                            <hr />
                            <p>
                            We aid businesses in establishing IAM in a timely, affordable, and secure manner.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="common-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="h2-wrapper">
                            <h2>The iamcore code of culture</h2>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="item-wrapper">
                                    <h4>Simplicity</h4>
                                    <div className="p-wrapper">
                                        <p>We aim straight to the point and strive to get to the heart of the matter without getting bogged down in unnecessary formalities.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="item-wrapper">
                                    <h4>Transparency</h4>
                                    <div className="p-wrapper">
                                        <p>We foster an environment of open communication and cooperation where ideas may be freely exchanged and developed.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="item-wrapper">
                                    <h4>Trust</h4>
                                    <div className="p-wrapper">
                                        <p>The cornerstone of successful collaboration is our confidence in our team and the product we are passionate about.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="item-wrapper">
                                    <h4>Commitment</h4>
                                    <div className="p-wrapper">
                                        <p>We are devoted to our common goal, which is to provide a solution that is valuable to our clients and the community.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </Layout>
    )
}

export const Head = () => <Seo title="Our Story" description="We keep sensitive assets safe with centralized identity and access management." image={openGpaphImage}/>

export default About
